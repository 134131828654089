import { createI18n } from "vue-i18n";

import ru from '@/i18n/ru.json';

const i18n = createI18n({
    legacy: false,
    locale: "ru",
    globalInjection: true,
    warnHtmlMessage: false,
    messages: {
        ru: ru
    }
});

export default i18n