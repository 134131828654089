<template>
    <div id="app">
        <router-view />
    </div>
</template>

<style>
#app {
    color: var(--bs-body-color);
}

nav a.router-link-exact-active {
    color: #42b983!important;
    font-weight: 500;
}

nav .nav-link:hover {
    color: #42b983!important;
}
</style>
