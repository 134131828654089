import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        name: "home",
        component: function () {
            return import("../views/HomeView.vue");
        },
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: function () {
            return import("../views/dashboard/DashboardView.vue");
        },
        meta: { requiresAuth: true },
    },
    {
        path: "/dashboard/passwords",
        name: "Пароли",
        component: function () {
            return import("../views/dashboard/PasswordsView.vue");
        },
        meta: { requiresAuth: true },
    },
    {
        path: "/dashboard/statistics",
        name: "Статистика",
        component: function () {
            return import("../views/dashboard/StatisticsView.vue");
        },
        meta: { requiresAuth: true },
    },
    {
        path: "/dashboard/discord",
        name: "Дискорд",
        component: function () {
            return import("../views/dashboard/DiscordServerInfo.vue");
        },
        meta: { requiresAuth: true },
    },
    {
        path: "/dashboard/discord/orders",
        name: "Заказы",
        component: function () {
            return import("../views/dashboard/discord/OrdersView.vue");
        },
        meta: { requiresAuth: true },
    },
    {
        path: "/discord",
        redirect: "https://discord.com/invite/TyQdwdMVgH",
    },
    {
        path: "/login",
        name: "login",
        component: function () {
            return import("../views/LoginView.vue");
        },
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem("user")) {
                // Пользователь уже авторизован, направляем на страницу dashboard
                next({ name: "dashboard" });
            } else {
                // Пользователь не авторизован, продолжаем нормальный процесс
                next();
            }
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// Навигационный охранник
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // Этот маршрут требует авторизации
        if (!localStorage.getItem("user")) {
            // Пользователь не авторизован, перенаправляем на страницу входа
            next({ name: "login" });
        } else {
            // Пользователь авторизован, продолжаем нормальный процесс
            next();
        }
    } else {
        // Этот маршрут не требует авторизации, продолжаем нормальный процесс
        next();
    }
});

export default router;
