import { toast } from 'vue3-toastify';
import { ref } from 'vue';
import i18n from '@/plugins/i18n.js'
import 'vue3-toastify/dist/index.css';

const t = i18n.global.t;

const notify = {
    install(Vue) {
        const toastIds = ref([]);

        const notify = {
            loading(message) {
                const id = toast.loading(t(message));
                toastIds.value.unshift(id);
            },
            remove() {
                const id = toastIds.value.pop();
                toast.remove(id);
            },
            update(message, type, data = '', time = 5000) {
                const id = toastIds.value.pop();
                toast.update(id, {
                    render: t(message, { data: data }),
                    autoClose: time,
                    closeOnClick: true,
                    closeButton: true,
                    type: type,
                    isLoading: false,
                });
            },
            info(message, time = 5000) {
                toast.info(message, {
                    theme: 'light',
                    dangerouslyHTMLString: true,
                    autoClose: time
                });
            },
            success(message, time = 5000) {
                toast.success(message, {
                    theme: 'light',
                    dangerouslyHTMLString: true,
                    autoClose: time
                });
            },
            warning(message, time = 5000) {
                toast.warning(message, {
                    theme: 'light',
                    dangerouslyHTMLString: true,
                    autoClose: time
                });
            },
            error(message, time = 5000) {
                toast.error(message, {
                    theme: 'light',
                    dangerouslyHTMLString: true,
                    autoClose: time
                });
            },

        }

        Vue.config.globalProperties.$notify = notify
    }
}

export default notify