import { createStore } from "vuex";
import axios from "axios";

export default createStore({
    state: {
        user: JSON.parse(localStorage.getItem("user")) || null,
    },
    mutations: {
        login(state, user) {
            state.user = user;
            localStorage.setItem("user", JSON.stringify(user));
        },
        logout(state) {
            state.user = null;
            localStorage.removeItem("user");
        },
    },
    actions: {
        async logout({ commit }) {
            // Твой код для выхода (если требуется)
            commit("logout");
        },
    },
    getters: {
        isAuthenticated: (state) => {
            return state.user !== null;
        },
    },
});
