import { createApp } from 'vue';
import App from './App.vue';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import 'bootstrap-icons/font/bootstrap-icons.css';
import router from '@/router';
import store from "./store";
import notify from '@/plugins/notify';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import i18n from '@/plugins/i18n.js';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(i18n);
app.use(notify);

app.component('VueDatePicker', VueDatePicker);

app.mount('#app');